<template>
  <div>
    <v-form ref="form">
      <v-file-input
        :rules="[rules.required]"
        v-model="file"
        outlined
      />

      <v-btn @click="validateForm()" color="primary" small>Submit</v-btn>
    </v-form>
  </div>
</template>

<script>
import rules from '@/rules'
import ConvertApi from 'convertapi-js'

export default {
  data() {
    return {
      rules,
      file: null,
    }
  },

  methods: {
    async startConvert(file) {
      let convertApi = ConvertApi.auth('XdJ2gWqJfTCcfIy4')
      let params = convertApi.createParams()
      params.add('File', file)
      let result = await convertApi.convert('svg', 'jpg', params)
      console.log(result)
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        this.startConvert(this.file)
      }
    }
  }
}
</script>